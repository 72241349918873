import React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import Benefits from "src/components/pages/product/sections/benefits/Benefits";
import Header from "src/components/layout/Header/Header";
import NewHero from "src/components/pages/index/sections/NewHero/NewHero";
import Paragraph from "src/components/pages/index/sections/paragraph/Paragraph";
import OtherModules from "src/components/pages/product/sections/otherModules/OtherModules";
import CommonDescription from "src/components/layout/CommonDescription/CommonDescription";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import Patrons from "src/components/layout/Patrons/Patrons";

export default function CommunicationPage() {
  return (
    <Layout>
      <Seo />
      <Header>
        <NewHero pageName="communication" />
        <Paragraph title="Nowoczesny sposób komunikacji na linii firma-pracownik" desc="" />
      </Header>
      <Benefits pageName="communication" />
      <CommonDescription listColor="#287ABE" />
      <OtherModules pageName="communication" />
      <ContactFooter showForm={true} showInfo={true} />
      <Patrons />
    </Layout>
  );
}
